/* You can add global styles to this file, and also import other style files */
$bodyfont: 'Poppins', sans-serif;
$headingfont: 'Poppins', sans-serif;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, $bodyfont;
}

$white: #ffffff;
$radius: 4px;

/*******************
Mat card
*******************/

.mat-card {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
  margin: 15px;
  padding: 0px !important;
  border-radius: $radius;
  .mat-card-content {
    font-size: 1rem;
    line-height: 23px;
    padding: 24px;
  }
  .mat-card-image {
    width: calc(100% + 0px);
    margin: 0 0px 0px 0px;
  }
  .mat-card-header {
    padding: 12px;
    border-radius: $radius $radius 0 0;
    .mat-card-title {
      margin-bottom: 2px;
      font-size: 20px;
    }
    .mat-card-subtitle,
    .mat-card-subtitle:not(:first-child) {
      margin-bottom: 0px;
      margin-top: 0px;
      font-size: 14px;
    }
    &.text-white {
      .mat-card-title {
        color: $white;
      }
      .mat-card-subtitle {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  .mat-card-title {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .mat-card-subtitle {
    margin-bottom: 20px;
  }
  .mat-card-actions {
    margin: 0px !important;
  }
}

/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headingfont;
  font-weight: 400;
}

h1 {
  line-height: 40px;
  font-size: 36px;
}

h2 {
  line-height: 36px;
  font-size: 30px;
}

h3 {
  line-height: 30px;
  font-size: 21px;
}

h4 {
  line-height: 22px;
  font-size: 18px;
}

h5 {
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
}

h6 {
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
}

.m-0 {
  margin: 0 !important;
}

.m-b-0 {
  margin-bottom: 0px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-t-20 {
  margin-top: 20px;
}

.m-l-0 {
  margin-left: 0px;
}

.m-l-20 {
  margin-left: 20px !important;
}
.p-0 {
  padding: 0px !important;
}
.pointer {
  cursor: pointer;
}

.skeleton-loader:empty {
  width: 100%;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 70%
    ),
    #eff1f6;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

@keyframes shine {
  to {
    background-position: 100% 0;
  }
}
